<template>
  <b-container>
    <b-row>
      <b-col md="12">
        <h3 class="mb-1">
          Forgot Your Password?
        </h3>
      </b-col>
    </b-row>
    <b-row v-if="recoveryStep === 1">
      <b-col md="12">
        <b-card class="mb-1">
          <validation-observer
            ref="recoveryForm"
            #default="{ invalid, dirty }"
          >
            <b-form
              class="form-container"
              @submit.prevent="passwordRecovery"
            >
              <b-row class="mt-1">
                <b-col md="12">
                  <b-form-group
                    class="text-primary"
                    label="Email"
                    label-for="email"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      rules="required|email"
                      vid="email"
                    >
                      <b-form-input
                        id="email"
                        v-model.trim="userEmail"
                        type="text"
                        class="form-control"
                        placeholder="Email"
                        trim
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    class="text-primary"
                    label="Where do you want to receive the recovery link?"
                    label-for="receiver"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Receiver"
                      rules="required"
                      vid="receiver"
                    >
                      <b-form-radio
                        v-model="selectedReceiver"
                        name="receiver"
                        value="Email"
                      >
                        Email
                      </b-form-radio>
                      <b-form-radio
                        v-model="selectedReceiver"
                        name="receiver"
                        value="Mobile"
                      >
                        SMS
                      </b-form-radio>
                      <span class="text-danger">{{ errors[0] }}</span>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  class="form-group text-right"
                >
                  <b-button
                    variant="primary"
                    type="submit"
                    :disabled="invalid || !dirty"
                  >
                    Submit
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="recoveryStep === 2">
      <b-col md="12">
        <b-card class="mb-1">
          <validation-observer
            ref="newPasswordForm"
            #default="{ invalid, dirty }"
          >
            <b-form
              class="form-container"
              @submit.prevent="confirmNewPassword"
            >
              <b-row class="mt-1">
                <b-col md="12">
                  <b-form-group
                    class="text-primary"
                    label="Recovery Code"
                    label-for="recoveryCode"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Recovery Code"
                      rules="required"
                      vid="recoveryCode"
                    >
                      <b-form-input
                        id="recoveryCode"
                        v-model.trim="recoveryCode"
                        type="text"
                        class="form-control"
                        placeholder="Recovery Code"
                        trim
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="New Password"
                    label-for="password"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="New Password"
                      rules="required|min:7|confirmed:confirmPassword"
                      vid="password"
                    >
                      <b-form-input
                        id="password"
                        v-model.trim="password"
                        type="password"
                        class="form-control"
                        placeholder="New Password"
                        autocomplete="new-password"
                        trim
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Confirm New Password"
                    label-for="confirmPassword"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Confirm New Password"
                      rules="required|min:7|confirmed:password"
                      vid="confirmPassword"
                    >
                      <b-form-input
                        id="confirmPassword"
                        v-model.trim="confirmPassword"
                        type="password"
                        class="form-control"
                        placeholder="Confirm New Password"
                        autocomplete="new-password"
                        trim
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  class="form-group text-right"
                >
                  <b-button
                    variant="primary"
                    type="submit"
                    :disabled="invalid || !dirty"
                  >
                    Submit
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BContainer, BRow, BCol,
  BButton, BCard,
  BForm, BFormGroup, BFormInput, BFormRadio,
} from 'bootstrap-vue'
import {
  required, email, confirmed, min,
} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'
import IndexBackground from '@/assets/images/backgrounds/auth.png'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BButton,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormRadio,
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      recoveryStep: 1,
      userEmail: '',
      selectedReceiver: '',
      recoveryCode: '',
      password: '',
      confirmPassword: '',

      required,
      email,
      confirmed,
      min,
    }
  },
  created() {
    document.body.style.backgroundImage = `url(${IndexBackground})`
    document.body.style.backgroundSize = 'cover'
  },
  mounted() {
  },
  methods: {
    passwordRecovery() {
      this.$refs.recoveryForm.validate().then(success => {
        if (success) {
          useJwt[`recoverPassword${this.selectedReceiver}`]({
            email: this.userEmail,
          })
            .then(response => {
              console.log(`recoverPassword${this.selectedReceiver}`, response.data.data)
              const recoveryRes = response.data.data

              // confirm message
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Recovery link sent',
                  icon: 'BellIcon',
                  variant: 'success',
                  text: `A link has been sent to ${recoveryRes.display_email || recoveryRes.display_mobile}. It will be valid for ${recoveryRes.code_duration}.`,
                },
              })
              this.recoveryStep = 2 // go to step 2
            })
            .catch(error => {
              // highlight Email field with the received error
              if (error.response.data.error === 'Unauthorized') {
                this.$refs.recoveryForm.setErrors({ email: 'Incorrect email' })
              } else {
                this.$refs.recoveryForm.setErrors({ email: error.response.data.details || error.response.data.error })
              }
            })
        }
      })
    },
    confirmNewPassword() {
      this.$refs.newPasswordForm.validate().then(success => {
        if (success) {
          useJwt.resetPassword(
            this.recoveryCode,
            {
              password: this.password,
              c_password: this.confirmPassword,
            },
          )
            .then(() => {
              // confirm message
              this.$router.push({ name: 'auth-login' })
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Password Reset',
                      icon: 'BellIcon',
                      variant: 'success',
                      text: 'Please log in with your new password.',
                    },
                  })
                })
            })
            .catch(error => {
              // highlight Email field with the received error
              if (error.response.data.error === 'Unauthorized') {
                this.$refs.recoveryForm.setErrors({ email: 'Incorrect email' })
              } else {
                this.$refs.recoveryForm.setErrors({ email: error.response.data.details || error.response.data.error })
              }
            })
        }
      })
    },
  },
}
</script>

<style>
</style>
